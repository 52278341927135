<template>
    <v-container>
        <v-tabs
            v-model="tab"
            background-color="transparent"
            color="basil"
            grow
        >
            <v-tab v-for="item in items" :key="item.text">
                <v-icon>{{ item.icon }}</v-icon>
                {{ item.text }}
            </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
            <v-tab-item>
                <list-supplier/>
            </v-tab-item>
            <v-tab-item>
                <supplier-to-pay :showTitle="false"/>
            </v-tab-item>
        </v-tabs-items>
    </v-container>
</template>

<script>
import ListSupplier from './ListSupplier'
import SupplierToPay from './SupplierToPay'
export default {
  name: 'SupplierInfo',
  components: { ListSupplier, SupplierToPay },
  data () {
    return {
      tab: null,
      items: [{
        text:
            this.$vuetify.lang.t('$vuetify.titles.list', [
              this.$vuetify.lang.t('$vuetify.menu.supplier')
            ]),
        icon: 'mdi-chart-bar'
      }, {
        text: this.$vuetify.lang.t('$vuetify.menu.account_to_pay'),
        icon: 'mdi-file-document'
      }]
    }
  }
}
</script>

<style scoped>

</style>
